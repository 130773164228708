<html>
  <head>
    <meta name="robots" content="noindex">
  </head>

<body>
<div class="body">
  <div *ngIf="cargado" class="container">
    <div class="botones">
      <button (click)="abrirDialogActualizarContrasena(true)" class="boton">Actualizar contraseña</button>
      <button (click)="abrirDialogBorrarUsuario()" class="boton borrar_usuario"> Eliminar usuario </button>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="saveData()" novalidate>  
      <!-- Fila de email -->
      <div class="form-group row  mt-5">
        <div class="my-2 p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Email</mat-label>
            <input (input)="hayModificacion()" matInput formControlName="email" autocomplete="off" />
            <mat-error *ngIf="getForm.email.invalid">{{getErrorMessageEmail()}}</mat-error>
          </mat-form-field>
        </div>

        <!-- Fila del telefono -->
        <div class="my-2 p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <mat-form-field hintLabel="Min. 7 dígitos" class="col-12" appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input (input)="hayModificacion()" matInput #inputTelefono maxlength="20" formControlName="telefono" autocomplete="off" />
            <mat-error *ngIf="getForm.telefono.invalid">{{getErrorMessageTelefono()}}</mat-error>
            <mat-hint align="end">{{inputTelefono.value?.length || 0}}/20</mat-hint>
          </mat-form-field>
        </div>
        
        <!-- Fromulario de Celular -->
        <p *ngIf="userData.celular" class="col-12">Su número de celular actual es: <b>{{userData.celular}}</b>, 
          en caso de modificarlo, recuerde <b style="color: red;">verificarlo</b></p>
        <p *ngIf="!userData.celular" class="col-12">Celular: <b style="color: red;">Recuerde verificarlo para continuar</b></p>
        <app-phone-form (numeroFinal)="numeroFinal($event)" ></app-phone-form>

        <div class="my-2 p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Fecha de nacimiento</mat-label>
            <input (input)="hayModificacion()" formControlName="fechaNacimiento" matInput [max]="fecha_max" [matDatepicker]="nuevaFecha" />
            <mat-error *ngIf="getForm.fechaNacimiento.invalid">{{getErrorMessageFechaNac()}}</mat-error>                                                
            <mat-datepicker-toggle matSuffix [for]="nuevaFecha" (click)="hayModificacion()"></mat-datepicker-toggle>
            <mat-datepicker #nuevaFecha></mat-datepicker>
          </mat-form-field>
        </div>


          <!-- CAMPO DE PROVINCIA, QUEDARÁ PARA CUANDO SE IMPLEMENTÉ LA MISMA COMO DATO DEL PACIENTE -->
          <!-- <div class="p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <mat-form-field class="col-12" appearance="outline">
                  <mat-label>Provincia</mat-label>
                  <mat-select id="inputProvincia">

                      <mat-option value="1">Buenos Aires</mat-option>
                      <mat-option value="2">Catamarca</mat-option>
                      <mat-option value="3">Chaco</mat-option>
                      <mat-option value="4">Chubut</mat-option>
                      <mat-option value="5">Córdoba</mat-option>
                      <mat-option value="6">Corrientes</mat-option>
                      <mat-option value="7">Entre Ríos</mat-option>
                      <mat-option value="8">Formosa</mat-option>
                      <mat-option value="9">Jujuy</mat-option>
                      <mat-option value="10">La Pampa</mat-option>
                      <mat-option value="11">La Rioja</mat-option>
                      <mat-option value="12">Mendoza</mat-option>
                      <mat-option value="13">Misiones</mat-option>
                      <mat-option value="14">Neuquén</mat-option>
                      <mat-option value="15">Río Negro</mat-option>
                      <mat-option value="16">Salta</mat-option>
                      <mat-option value="17">San Juan</mat-option>
                      <mat-option value="18">San Luis</mat-option>
                      <mat-option value="19">Santa Cruz</mat-option>
                      <mat-option value="20">Santa Fe</mat-option>
                      <mat-option value="21">Santiago del Estero</mat-option>
                      <mat-option value="22">Tierra del Fuego</mat-option>
                      <mat-option value="23">Tucumán</mat-option>
                  </mat-select>
              </mat-form-field>
          </div> -->

          <div class="my-2 p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <mat-form-field hintLabel="Máx. 25 caracteres" class="col-12" appearance="outline">
              <mat-label>Localidad</mat-label>
              <input (input)="hayModificacion()" matInput autocomplete="off" #inputLocalidad maxlength="25" formControlName="localidad">
              <mat-error *ngIf="getForm.localidad.invalid">{{getErrorMessageLocalidad()}}</mat-error>
              <mat-hint align="end">{{inputLocalidad.value?.length || 0}}/25</mat-hint>
            </mat-form-field>
          </div>

          <div class="my-2 p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <mat-form-field hintLabel="Máx. 4 caracteres" class="col-12" appearance="outline">
              <mat-label>Código postal</mat-label>
              <input (input)="hayModificacion()" matInput autocomplete="off" #inputCP maxlength="4" formControlName="cp">
              <mat-error *ngIf="getForm.cp.invalid">{{getErrorMessageCP()}}</mat-error>
              <mat-hint align="end">{{inputCP.value?.length || 0}}/4</mat-hint>
            </mat-form-field>
          </div>

          <div class="my-2 p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <mat-form-field hintLabel="Máx. 40 caracteres" class="col-12" appearance="outline">
              <mat-label>Domicilio</mat-label>
              <input (input)="hayModificacion()" autocomplete="off" matInput #inputDomicilio maxlength="40" formControlName="domicilio" id="inputDomicilio">
              <mat-error *ngIf="getForm.domicilio.invalid">{{getErrorMessageDomicilio()}}</mat-error>
              <mat-hint align="end">{{inputDomicilio.value?.length || 0}}/40</mat-hint>
            </mat-form-field>
          </div>

          <div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="form-group">
                <label for="fotoPerfil">Foto de perfil</label>
                <input (input)="hayModificacion()" [disabled]="loading" type="file" (change)="onFileChange($event)" accept="image/*, .heic"
                  id="fotoPerfil" class="form-control"
                  />
                <mat-hint > Max. 10 MB </mat-hint>
                <div
                  *ngIf="getForm.fotoPerfil.errors"
                  class="invalid-feedback"
                >
                </div>
                <div *ngIf="getForm.fotoPerfil.errors" class="invalid-feedback">
                  <div *ngIf="getForm.fotoPerfil.errors.archivoInvalido">
                  Solo se admiten imagenes
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="d-flex justify-content-center" >
        <button 
          mat-raised-button  
          type="submit" 
          class="boton" 
          (click)="submit" 
          [disabled]="this.loadingSubmit || !this.hay_modificacion || formularioInvalido()"                    
          [class.spinnerButton]="loadingSubmit">
          Confirmar
        </button>
      </div>
    </form>
  </div>
</div>

</body>
</html>