<div *ngIf="data.ok" class="barra-inicial barra-inicial-celeste">
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">    
</div>
<div *ngIf="!data.ok" class="barra-inicial barra-inicial-roja">
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">    
</div>

<div *ngIf="data.titulo">
    <h1 mat-dialog-title class="tituloDialog" style="padding-left: 0%;">{{ data.titulo }}</h1>
    <mat-divider class="divisor"></mat-divider>
</div>
  
<div mat-dialog-content>
    <div >
        <p class="alert alert-success p-1 m-1 align-middle text-center" style="max-width: 500px; white-space: pre-line; line-height: 1.0;" [innerHTML]="data.mensaje">
            {{ data.mensaje }}
        </p>
    </div>
    <ng-template #resultadoNegativo>
        <p class="alert alert-danger p-1 m-1 align-middle text-center" style="max-width: 500px; white-space: pre-line; line-height: 1.0;"  [innerHTML]="data.mensaje">
            {{ data.mensaje }}
        </p>
    </ng-template>
</div>

<div mat-dialog-actions align="end" style="margin-top: 10x; display: flex; justify-content: center;">
    <ng-container *ngFor="let boton of data.botones">
        <button
            mat-button
            class="boton"
            [color]="boton.color || 'primary'"
            (click)="ejecutarAccion(boton)"
        >
            {{ boton.texto }}
        </button>
    </ng-container>
</div>
