import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { UserService } from '../_services/user.service';
import { TurnosService } from '../_services/turnos.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { DialogSoporteTecnicoComponent } from '../dialog-soporte-tecnico/dialog-soporte-tecnico.component';
import { DialogOlvideMisDatosComponent } from '../dialog-olvide-mis-datos/dialog-olvide-mis-datos.component';
import { DialogMensajeAyudaComponent } from '../dialog-mensaje-ayuda/dialog-mensaje-ayuda.component';
import { error, log } from 'console';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  mostrarMas: boolean;
  loginForm: FormGroup;
  recoverPasswordForm: FormGroup;
  supportMessageForm: FormGroup;
  recoverUsernameForm: FormGroup;
  recoveredUsersForm: FormGroup;
  loading = false;
  submitted = false;
  recoverUsernameFormReady = false;
  recoveredUsers;
  returnUrl: string;
  supportMessageSubmitted = false;
  recoveredUsersFormSubmitted = false;
  supportMessageLoading = false;
  dataSource: any;
  fechaDelTurno: string;
  fechaDeHoySistema: string;
  fechaArray: string[];
  fechaArrayDos: string[];
  hayReservaHoy = false;
  fecha: Date;
  msje: string;
  turnoHoy: any;
  respuesta = true;
  showPass = false;
  bloq_mayus_activado = false;
  loginFallidos: number = 0;
  mostrarContraseña: boolean = false;

  caracter_no_permitido;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService,
    private turnosService: TurnosService,
    private dialog: MatDialog,     
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
     // this.router.navigate(['/turnos']);
    }
  }
  ngOnInit() {  

    // this.logout();
    this.declarar_event_listeners();

    document.getElementById('openModalVideo').click();


    this.mostrarMas = false;

    // this.hayReservaHoy = false;

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required, Validators.pattern('[a-zA-Z0-9\u00f1_.-]*')],
      password: ['', Validators.required],  
    });

    this.recoverPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
    });

    this.recoverUsernameForm = this.formBuilder.group({
      //  dni: ['', [Validators.required, Validators.maxLength(8), Validators.minLength(7), Validators.pattern('[0-9]*')]],
      mail: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
    });

    this.recoveredUsersForm = this.formBuilder.group({
      user: ['', Validators.required],
    });

    this.supportMessageForm = this.formBuilder.group({
      nombreApellido: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$')],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
      asunto: ['', Validators.required],
      mensaje: ['', Validators.required],
    });

    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  logout(){
    this.authenticationService.logout();
    sessionStorage.clear();
    const passwordInput = document.getElementById('input_password') as HTMLInputElement;

    if (passwordInput.value === '') {
      const usernameInput = document.getElementById('input_usuario') as HTMLInputElement;
      usernameInput.value = '';
    }
  }     

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
  get rp() {
    return this.recoverPasswordForm.controls;
  }
  get sp() {
    return this.supportMessageForm.controls;
  }
  get ru() {
    return this.recoverUsernameForm.controls;
  }
  get rec() {
    return this.recoveredUsersForm.controls;
  }

  onSubmit() {    
    this.submitted = true;

    // stop here if form is invalid
    if (this.f.username.invalid || this.f.password.invalid) {
      return;
    }

    this.loading = true;
    
    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {          
          
          if (data.token) {
            // this.iniciarUsuario();
            // this.tieneTurnosReservados();  
            window.location.href = '/home';       
            
          } else {
            // fallo logueo, me fijo si se debe guardar el intento
            this.loginFallidos++
            if (this.loginFallidos == 2){
              this.openDialogMensajeAyuda()
            }

            this.authenticationService.verificarParametroIntento().subscribe(
              parametro => {
                if(parametro){
                  // guardo el intento
                  this.authenticationService.guardarIntento(data.usuarioIntento, data.passwordIntento, this.mostrarContraseña).subscribe(
                    (respuestaGuardarIntento) =>{
                      // console.log(respuestaGuardarIntento);
                      if (!this.showPass)  
                        this.mostrarContraseña = false;              
                    },
                    error => {
                      console.error(error);                
                    }
                  );
                }
              },
              error => {
                console.error(error);   
              }           
            )
            
            if (data.usuarioActivo) {
              this.msje = 'Contraseña incorrecta';
            } else {
              this.msje = 'El usuario no existe'
            }
            this.loading = false;
          }
        }, (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  // iniciarUsuario() {
  //   this.turnosService.getTurnosReservadosPaciente().subscribe(
  //     (response) => {
  //       //console.log(response);
        
  //       this.dataSource = response.turnos;
  //       if (this.dataSource.length > 0) {
  //         for (let index = 0; index < this.dataSource.length; index++) {
  //           const element = this.dataSource[index];

  //           this.fechaArrayDos = element.str_fecha.split('/');
  //           this.fechaArray = new Date(
  //             this.fechaArrayDos[1] +
  //               '/' +
  //               this.fechaArrayDos[0] +
  //               '/' +
  //               this.fechaArrayDos[2]
  //           )
  //             .toLocaleDateString()
  //             .split('/');
  //           this.fechaDelTurno =
  //             this.fechaArray[0] +
  //             '/' +
  //             this.fechaArray[1] +
  //             '/' +
  //             this.fechaArray[2];
  //           this.fechaDeHoySistema = new Date().toLocaleDateString();

  //           if (this.fechaDelTurno === this.fechaDeHoySistema) {
  //             this.turnosService.habilitarWebcheckin(element).subscribe(
  //               (responseHabWC) => {
  //                 if (responseHabWC.ok) {
  //                   // this.router.navigate(['/mis-reservas']);
  //                   window.location.href='/turnos-reservados';
  //                 } else {
  //                   // this.router.navigate(['/home']);
  //                   window.location.href='/home';
  //                 }
  //               },
  //               (error) => {
  //                 console.log(error);
  //               }
  //             );
  //             break;
  //           } else {
  //             // this.router.navigate(['/home']);
  //             window.location.href='/home';
  //           }
  //         }
  //       } else {
  //         // this.router.navigate(['/home']);
  //         window.location.href='/home';
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  recoverPassword() {
    this.submitted = true;
    if (this.recoverPasswordForm.invalid) {
      return;
    }

    this.userService
      .recoverPassword(this.recoverPasswordForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          //alert(data.mensaje);
          let msjDialog = {
            ok: true,
            mensaje: data.mensaje,
          }
          this.openDialog(msjDialog);
          document.getElementById('openContraseñaModalButton').click();
        },
        (error) => {
          this.recoverPasswordForm.controls['username'].setErrors({
            incorrect: true,
          });
        }
      );
  }

  recoverUsername() {
    this.recoveredUsersFormSubmitted = true;
    if (this.recoverUsernameForm.invalid) {
      return;
    }

    this.respuesta = false;
    this.userService.recoverUsername(this.recoverUsernameForm.value).subscribe(
      (data) => {
        this.recoveredUsers = data;        
        if (this.recoveredUsers.length == 1) {
          this.userService
            .sendMail({ user: this.recoveredUsers[0] })
            .pipe(first())
            .subscribe(
              (data) => {
                this.respuesta = true;
                //alert(data.mensaje);
                let msjDialog = {
                  ok: true,
                  mensaje: data.mensaje,
                }
                this.openDialog(msjDialog);
                this.closeRecoveredUsersFormModal();
                this.recoveredUsersFormSubmitted = false;
              },
              (error) => {
                this.respuesta = true;
              }
            );
        } else {
          this.recoverUsernameFormReady = true;
          this.recoveredUsersFormSubmitted = true;
          this.respuesta = true;
        }
      },
      (error) => {
        // this.recoverUsernameForm.controls['dni'].setErrors({'incorrect': true});

        this.respuesta = true;
        this.recoverUsernameForm.controls['mail'].setErrors({
          incorrect: true,
        });
      }
    );
  }

  supportMessage() {
    this.supportMessageSubmitted = true;
    if (this.supportMessageForm.invalid) {
      return;
    }
    this.supportMessageLoading = true;
    this.userService
      .supportMessage(this.supportMessageForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          // alert('El mail se envio con exito');
          // window.location.reload();
          let msjDialog = {
            ok: true,
            mensaje: "El mail se envió con éxito",
          }
        },
        (error) => {}
      );
  }

  onSubmitRecoveredUsersForm() {
    this.recoveredUsersFormSubmitted = true;
    this.respuesta = false;
    if (this.recoveredUsersForm.invalid) {
      return;
    }
    // this.supportMessageLoading = true;
    this.userService
      .sendMail(this.recoveredUsersForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.respuesta = true;
          // alert(data.mensaje);
          let msjDialog = {
            ok: true,
            mensaje: data.mensaje,
          }
          this.openDialog(msjDialog);
          this.closeRecoveredUsersFormModal();
          this.recoveredUsersFormSubmitted = false;
        },
        (error) => {
          this.respuesta = true;
          this.recoveredUsersFormSubmitted = false;
        }
      );
  }

  closeRecoveredUsersFormModal() {
    document.getElementById('openRecuperarUsuarioModalButton').click();
    this.recoverUsernameForm.reset();
    this.recoveredUsersForm.reset();
    this.recoverUsernameFormReady = false;
  }

  showHidePassword() {
    if ((!this.showPass) && (!this.mostrarContraseña))
      this.mostrarContraseña = true;
    this.showPass = !this.showPass;
  }

  declarar_event_listeners(){ // declarar todos los event listeners aca
    this.input_password_event_listeners();
  }

  input_password_event_listeners(){      
    document.getElementById('input_password').addEventListener('keypress', bloq_mayus_press);
    document.getElementById('input_password').addEventListener('click', bloq_mayus_press);
    document.getElementById('input_password').addEventListener('keydown', bloq_mayus_up);
    

    function bloq_mayus_press(e){
      if(e.getModifierState("CapsLock"))
      {
        document.getElementById('bloq_mayus_activado').style.display = 'block';
      }
      else {
        document.getElementById('bloq_mayus_activado').style.display = 'none';
      }
    }   

    function bloq_mayus_up(e){
      if(e.keyCode == 20){
        cambiar_estado_mensaje();
      }
    }
    
    function cambiar_estado_mensaje(){
      document.getElementById('bloq_mayus_activado').style.display == 'block' ? 
      document.getElementById('bloq_mayus_activado').style.display = 'none' :
      document.getElementById('bloq_mayus_activado').style.display = 'block';
    }
  }

  onKeypressEvent(e){
      
      var k; 
      this.caracter_no_permitido = e.key;
       
      k = e.charCode;  //         k = event.keyCode;  (los dos pueden usarse)
      
      if ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 57)
        || k==46 || k==45 || k==95 || k==241 || k==209) {
        document.getElementById('error-caracter-no-permitido').style.display = 'none';       
        return true;
      } else {        
        document.getElementById('error-caracter-no-permitido').style.display = 'inline-flex';
        return false;
      }
  }

  openDialog(respuesta){
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {ok:respuesta.ok, mensaje:respuesta.mensaje},
      panelClass: 'dialog-sin-overflow'
    })
  }

  openDialogSoporteTecnico(){    
    this.dialog.open(DialogSoporteTecnicoComponent, {
      hasBackdrop: false,
      data: { openedFromAyudaDialog: false },
      //width: '50%'
    })
  }

  openDialogOlvideMisDatos(){
    this.dialog.open(DialogOlvideMisDatosComponent, { 
      hasBackdrop: false,
    })
  }

  openDialogMensajeAyuda(){
    this.dialog.open(DialogMensajeAyudaComponent, { 
      hasBackdrop: true,
      height: '90vh',      
    })
  }

  irRegistro(){
    window.location.href='/register';
  }

  // tieneTurnosReservados() {
  //   this.turnosService.getTurnosReservadosPaciente().subscribe(
  //     (response) => {        
        
  //       if(response.turnos.length > 0){
  //         window.location.href = '/turnos-reservados';
  //       }else{
  //         window.location.href = '/home';
  //       }
  //     },        
  //     (error) => {
  //       console.log(error);
        
  //     })
    
  //   }

}

