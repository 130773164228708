<form [formGroup]="phoneForm">
  <div class="form-row">  
    <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <!-- Selector de código de país -->
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Código de País</mat-label>
        <mat-select formControlName="codPais" (selectionChange)="onCountryChange($event.value)">
          <mat-option *ngFor="let pais of paises" [value]="pais.code">
            <img [src]="pais.flag" class="flag-icon" alt="{{pais.name}}"> {{ pais.code }} ({{pais.name}})
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.codPais.invalid">{{ getErrorMessageCodPais() }}</mat-error>
      </mat-form-field>

      <!-- Input de código de área -->
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Código de área</mat-label>
        <input matInput formControlName="codArea" autocomplete="off" [maxlength]="maxLengthCodigoArea" />
        <mat-error *ngIf="f.codArea.invalid">{{ getErrorMessageCodArea() }}</mat-error>
        <mat-hint>Sin el primer 0 (cero)</mat-hint>
      </mat-form-field>
    </div>

    <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <!-- Input de número de abonado -->
      <mat-form-field class="col-9" appearance="outline">
        <mat-label>Número de celular</mat-label>
        <input matInput formControlName="numAbonado" autocomplete="off" maxlength="10" />
        <mat-error *ngIf="f.numAbonado.invalid">{{ getErrorMessageNumAbonado() }}</mat-error>
        <mat-error *ngIf="phoneForm.errors?.invalidPhoneNumber">{{ getPhoneErrorMessage() }}</mat-error>
        <mat-hint>Sin el 15</mat-hint>
        <mat-hint align="end">
          {{ f.codArea.value?.length + f.numAbonado.value?.length || 0 }}/10
        </mat-hint>
      </mat-form-field>

      <button 
        *ngIf="!verificado && !numInvalido" 
        class="btn btn-info col-3" 
        (click)="onSubmit()"
        [disabled]="phoneForm.invalid"
        >Verificar
      </button>
      <button *ngIf="verificado && !numInvalido" class="btn btn-success col-3" disabled>Verificado</button>
      <button *ngIf="numInvalido" class="btn btn-danger col-3" disabled>Numero inválido</button>
    </div>
  </div>
</form>