import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TwilioService } from '../_services/twilio.service';
import { Pais } from './paises'; 
import { PAISES } from './paises';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.css'],
})
export class PhoneFormComponent{
  @Output() numeroFinal = new EventEmitter<string>();

  phoneForm: FormGroup;
  paises = PAISES;
  paisSeleccionado: Pais = PAISES[0];
  maxLengthCodigoArea: number = 4;
  verificado: boolean = false;
  numInvalido: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private twilioService: TwilioService,
  ) {
    this.initForm();
    const camposNumericos = ['codArea', 'numAbonado'];

    camposNumericos.forEach((campo) => {
      this.phoneForm.get(campo)?.valueChanges.subscribe(() => {
        this.escaparCaracteresEspeciales(campo);
      });
    });
    
  }

  initForm(){
    this.phoneForm = this.formBuilder.group({
      codPais: [
        this.paisSeleccionado.code,
        [
          Validators.required,
        ],
      ],
      codArea: [
        "",
        [
          Validators.required, 
          Validators.pattern("[0-9]*")],
          Validators.minLength(2),
          Validators.maxLength(this.maxLengthCodigoArea)
      ],
      numAbonado: [
        "",
        [
          Validators.required,
          Validators.maxLength(9),
          Validators.minLength(6),
          Validators.pattern("[0-9]*"),
        ],
      ]
    });
    const campos = ['codPais', 'codArea', 'numAbonado'];

    campos.forEach((campo) => {
      this.phoneForm.get(campo)?.valueChanges.subscribe(() => {
        this.verificado = false;
        this.numInvalido = false;
        this.numeroFinal.emit("");
      });
    });

  }

  get f() {
    return this.phoneForm.controls;
  }

  onCountryChange(codPais: string) {
    this.setPaisSeleccionado(codPais);
    this.phoneForm.get('codArea')?.reset();
    this.phoneForm.get('numAbonado')?.reset(); 
  }

  setPaisSeleccionado(codPais: string) {
    this.paisSeleccionado = this.paises.find(pais => pais.code === codPais) || PAISES[0];
    this.maxLengthCodigoArea = this.paisSeleccionado.areaCodeLength || 4;
    this.phoneForm.get('codArea')?.setValidators([
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(2),
      Validators.maxLength(this.maxLengthCodigoArea)
    ]);
    this.phoneForm.get('codArea')?.updateValueAndValidity();
  }

  getErrorMessageCodArea() {
    return this.phoneForm.controls.codArea.hasError("pattern")
      ? "Ingrese sólo números"
      : this.phoneForm.controls.codArea.hasError("minlength")
      ? "Ingrese un numero de al menos 2 dígitos"
      : this.phoneForm.controls.codArea.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }
  getErrorMessageNumAbonado() {
    return this.phoneForm.controls.numAbonado.hasError("pattern")
      ? "Ingrese sólo números"
      : this.phoneForm.controls.numAbonado.hasError("minlength")
      ? "Ingrese un numero de al menos 6 dígitos"
      : this.phoneForm.controls.numAbonado.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }
  

  onSubmit(){
    let numeroCompleto: string =
    (this.phoneForm.get('codPais')?.value?.toString() || '') +
    (this.phoneForm.get('codArea')?.value?.toString() || '') +
    (this.phoneForm.get('numAbonado')?.value?.toString() || '');
  this.twilioService.verificarNumeroCelular(numeroCompleto).subscribe(
    (response) => {
      this.verificado = response;
      if (response){
        this.numeroFinal.emit(numeroCompleto);
      } else {
        this.numInvalido = true;
      }
    },
    (error) => {
      console.error('Error en validación:', error);
      this.verificado = false;
    }
  );
  }

  escaparCaracteresEspeciales(campo: string){
    this.phoneForm.get(campo)?.valueChanges.subscribe(value => {
      if (value) {
        const cleanedValue = value.replace(/[^0-9]/g, '');
          this.phoneForm.get(campo)?.setValue(cleanedValue, { emitEvent: false });
        }
      }
    );
  }

}
