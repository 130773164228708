import { Component, ViewChild, OnInit} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from './_services/authentication.service';

import { NavBarServiceService } from './_services/nav-bar-service.service';
import { User } from './_models/user';
import { UserService } from './_services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';

export interface UserData {
  apellido: string;
  campania_actualizacion: string;
  codigoPostal: string;
  domicilio: string;
  email: string;
  estadoValidacion?: string;
  fechaNacimiento: Date;
  localidad: string;
  masculino: boolean;
  nroDocumento: string;
  permisos?: string;
  socioNro?: string;
  telefonoParticular: string;
  tipoDocumento: string;
  usuario: string;
  fotoPerfil: String;
  celular?: String;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {

  title = 'sitio-de-pacientes';
  // usuario_logueado: boolean;
  currentUser: User;
  userData: UserData;
  fotoPaciente: any;
  no_logueado;
  version_mobile: boolean;

  @ViewChild(MatSidenav, { static: true }) sideNav: MatSidenav;
  constructor(
    private navBarService: NavBarServiceService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
    public platform: Platform,
  )
  { 
    this.authenticationService.currentUser.subscribe(async (x) => {
      this.currentUser = x;

      if (localStorage.getItem('currentUser') != null) {
        this.userService.getUserData().subscribe(async (x) => {
          this.userData = x;
          this.authenticationService.cambiarFotoPerfil(this.userData.fotoPerfil);
          //this.fotoPaciente = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
          //       + this.userData.fotoPerfil);       
        }
        , error => {
          console.log(error);
        });
      }

    }, error => {
      console.log(error);
    });

    // Establecer si es version mobile
    (platform.ANDROID || platform.IOS) ? this.version_mobile=true : this.version_mobile=false;      
    this.version_mobile ? this.authenticationService.setVersionWeb('Mobile') :
      this.authenticationService.setVersionWeb('Escritorio');    
  }
  
  ngOnInit() {   
    this.navBarService.setDrawer(this.sideNav); 
  }

  es_path_login(){
    return (window.location.pathname=='/' || window.location.pathname=='/login') ? true : false;
  }
  
  public static get testUrl(): string {
    const port = '';
    
    if (window.location.hostname === 'localhost') {
      return window.location.protocol + '//' + window.location.hostname + ':8080' + '/api';
    } else {
      return (
        window.location.protocol + '//' + window.location.hostname + '/api'
      );
    }
  }

  public static get SMApi(): string {
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      '/horus_restfull/api'
    );
  }

  public static get sucursal(): string {
    return '1'; // 1 ESPAÑOL - 2 CITY BELL
  }

  
}