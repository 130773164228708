import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Boleta, MercadoPago, MutualTurno } from '../autorizar-cobertura/autorizar-cobertura.component';
import { DialogLlegadaPacienteComponent } from '../dialog-llegada-paciente/dialog-llegada-paciente.component';
import { MercadoPagoService } from '../_services/mercado-pago.service';
import { TurnosService } from '../_services/turnos.service';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-autorizar-cobertura-resultado',
  templateUrl: './dialog-autorizar-cobertura-resultado.component.html',
  styleUrls: ['./dialog-autorizar-cobertura-resultado.component.css']
})
export class DialogAutorizarCoberturaResultadoComponent implements OnInit {

  existeError: boolean = false;
  existePago: boolean = false;
  pagaParticular: boolean = false;
  cargando: boolean = true;
  turnoAutorizar: any;
  mensajeAutorizar: string = '';
  autorizoOK: boolean;
  valorizoParticular: boolean = false;
  importeTotal: number = 0;
  urlPagoMp: string;
  hayPago: boolean = false;
  mutualTurno = {} as MutualTurno;
  valorizacionParticular: boolean = false;
  boletaCrear = {} as Boleta;
  nroBono;
  mercadoPago = {} as MercadoPago;


  constructor(
    private userService: UserService,
    private turnoService: TurnosService,
    private mercadoPagoService: MercadoPagoService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log(data)
  }

  ngOnInit() {
    this.obtenerDatosTurno();
  }

  async crearLiquidarBoletaImportes() {
    await this.userService.crearBoletaAmbulatoria(this.mutualTurno).toPromise().then(async rpCrearLiquidar => {
      // console.log(rpCrearLiquidar);
      if (rpCrearLiquidar.ok) {
        if (rpCrearLiquidar.importeTotal > 0) {   // TIENE QUE PAGAR
          this.existePago = true;
          this.mercadoPago.importeTotal = rpCrearLiquidar.importeTotal;
          this.mercadoPago.nroBoleta = rpCrearLiquidar.boleta;
          await this.mercadoPagoService.obtenerLinkPago(this.mercadoPago).toPromise().then(rpGetLinkMp => {
            // console.log(rpGetLinkMp);
            if (rpGetLinkMp && rpGetLinkMp.id) {
              if (this.pagaParticular) {   // SI ELIGIO PAGAR PARTICULAR REDIRECCIONO AUTOMATICAMENTE
                window.location.href = this.urlPagoMp + rpGetLinkMp.id;
              } else {   // SINO, ARMO LINK PARA IR A PAGAR
                this.urlPagoMp = this.urlPagoMp + rpGetLinkMp.id;
                this.cargando = false;
              }
            } else {
              this.mensajeAutorizar = 'Ocurrio un error (COD: 1009). Intente nuevamente.';
              this.existeError = true;
              this.cargando = false;
            }
          }, error => {
            console.log(error);
            this.mensajeAutorizar = 'Ocurrio un error (COD: 1008). Intente nuevamente.';
            this.existeError = true;
            this.cargando = false;
          })
        } else {   // NO TIENE QUE PAGAR, DEBERIA REGISTRAR LLEGADA
          this.existePago = false;
          this.cargando = false;
        }
      } else {
        this.mensajeAutorizar = 'Ocurrio un error (COD: 1007). Intente nuevamente.';
        this.existeError = true;
        this.cargando = false;
      }
    }, error => {
      console.log(error);
      this.mensajeAutorizar = 'Ocurrio un error (COD: 1006). Intente nuevamente.';
      this.existeError = true;
      this.cargando = false;
    })
  }

  async autorizarConsulta() {
    await this.userService.autorizarConsulta(this.mutualTurno).toPromise().then(async rpAutorizarConsulta => {
      // console.log(rpAutorizarConsulta)
      this.mensajeAutorizar = rpAutorizarConsulta.mensaje;
      this.autorizoOK = rpAutorizarConsulta.ok;
      this.valorizoParticular = rpAutorizarConsulta.valorizo_particular;
      this.mutualTurno.importe = rpAutorizarConsulta.importeDiferenciado;
      if (this.autorizoOK) {
        await this.crearLiquidarBoletaImportes();
      } else {
        this.cargando = false;
      }
    }, error => {
      console.log(error);
      this.mensajeAutorizar = 'Ocurrio un error al intentar autoriza la consulta (COD: 1003). Intente nuevamente.'
      this.autorizoOK = false;
      this.cargando = false;
    })
  }

  async setMutualTurnoAutorizarConsulta() {
    this.cargando = true;

    await this.turnoService.setMutualDelTurno(this.mutualTurno).toPromise().then(async rpSetMutual => {
      // console.log(rpSetMutual);
      if (rpSetMutual.ok) {
        await this.autorizarConsulta();
      } else {
        this.mensajeAutorizar = 'Ocurrio un error (COD: 1002). Intente nuevamente.'
        this.autorizoOK = false;
        this.cargando = false;
      }
    }, error => {
      console.log(error)
      this.mensajeAutorizar = 'Ocurrio un error (COD: 1001). Intente nuevamente.'
      this.autorizoOK = false;
      this.cargando = false;
    })
  }

  async obtenerDatosTurno() {
    this.turnoAutorizar = await this.turnoService.getTurnoAdmision();
    // console.log(this.turnoAutorizar)

    this.mutualTurno.usuario = this.turnoAutorizar.paciente.usuario;
    this.mutualTurno.matricula = this.turnoAutorizar.matricula;
    this.mutualTurno.fecha = this.turnoAutorizar.str_fecha;
    this.mutualTurno.desdeHora = this.turnoAutorizar.str_horaDesde;
    this.mutualTurno.orden = this.turnoAutorizar.numero;
    this.mutualTurno.idMutual = this.turnoAutorizar.mutualElegida.mutual;
    this.mutualTurno.nroCarnet = this.turnoAutorizar.nroCarnet;
    this.mutualTurno.pinSeguridad = this.turnoAutorizar.pinSeguridad;
    this.urlPagoMp = this.turnoAutorizar.urlPagoMp;

    this.setMutualTurnoAutorizarConsulta();
  }

  async pagarParticular() {
    this.cargando = true;
    this.pagaParticular = true;
    await this.turnoService.setMutualTurnoParticular(this.mutualTurno).toPromise().then(async rpSetMutualParticular => {
      // console.log(rpSetMutualParticular);
      if (rpSetMutualParticular.ok) {
        await this.crearLiquidarBoletaImportes();
      } else {
        this.mensajeAutorizar = 'Ocurrio un error (COD: 1005). Intente nuevamente.'
        this.autorizoOK = false;
        this.cargando = false;
      }
    }, (error) => {
      console.log(error);
      this.mensajeAutorizar = 'Ocurrio un error (COD: 1004). Intente nuevamente.'
      this.autorizoOK = false;
      this.cargando = false;
    });
  }

  pagar() {
    window.location.href = this.urlPagoMp;
  }

  registrarLlegada() {
    this.dialog.closeAll();

    this.dialog.open(DialogLlegadaPacienteComponent, {
      hasBackdrop: true,
      disableClose: true,
      maxWidth: 600,
      maxHeight: 560
    });

    this.userService.registrarLlegadaPaciente(this.mutualTurno).toPromise().then((rpRegistrarLLegada) => {
      // console.log(rpRegistrarLLegada)
    }, (error) => {
      console.log(error);
    });
  }

  cancelar() {
    this.dialog.closeAll();
    this.turnoService.setTurnoAdmision(null);
    this.router.navigate(['turnos-reservados']);
  }
}
