import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AuthenticationService } from "../_services/authentication.service";

import { UserService } from "../_services/user.service";
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";

import { SplitStringPipe } from "../_pipes/split-string.pipe";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { DialogMensajeComponent } from "../dialog-mensaje/dialog-mensaje.component";
import { DialogConfirmacionComponent } from "../dialog-confirmacion/dialog-confirmacion.component";
import heic2any from "heic2any";
import { DialogMensajeConfirmarRegistroComponent } from "../dialog-mensaje-confirmar-registro/dialog-mensaje-confirmar-registro.component";
import { DialogOlvideMisDatosComponent } from "../dialog-olvide-mis-datos/dialog-olvide-mis-datos.component";
import { DialogRegistroExitosoComponent } from "../dialog-registro-exitoso/dialog-registro-exitoso.component";


@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
  providers: [
    SplitStringPipe,
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  voyAValidar = false;
  validarForm: FormGroup;
  loadingValidar = false;
  submittedValidar = false;
  fecha_max: Date;
  fechaNacimientoString: string;
  currentUser: any;
  vengo_de_registrarme: boolean = false;
  loadingCargandoHeic: boolean;
  tiposDocumentos: any;
  email: String;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private bottomSheet: MatBottomSheet,
    private splitString: SplitStringPipe,
    public dialog: MatDialog
  ) {
    this.voyAValidar = sessionStorage.getItem("voyAValidar") != null;

    if (this.voyAValidar) {
      this.buildFormValidar_logueados();
      sessionStorage.removeItem("voyAValidar");
    } else {
      if (this.authenticationService.currentUserValue) {
        // estoy logueado y no voy a validar //estadoValidacion = PENDIENTE
        this.router.navigate(["/home"]);
      } else {
        // si no estoy logueado, me quiero registrar
        this.fecha_max = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        this.initForm();
      }
    }
  }

  ngOnInit() {
    this.recuperarTiposDeDocumentos();
  }

  recuperarTiposDeDocumentos() {
    this.userService.recuperarTiposDeDocumentos().subscribe(
      (data) => {
        this.tiposDocumentos = data.elementos;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  buildFormValidar() {
    let pattern_numeros = "[0-9]+";

    this.validarForm = this.formBuilder.group({
      fotoFrenteDNI: ["", Validators.required],
      fotoPaciente: ["", Validators.required],
      dni: [
        this.registerForm.value.dni,
        [
          Validators.required,
          Validators.pattern(pattern_numeros),
          Validators.minLength(6),
        ],
      ],
      tipoDni: ["", Validators.required], // se pone en el onSubmit
      dniTemporal: ["", Validators.required], // se pone en el onSubmit
      nombreUsuario: [
        this.registerForm.value.nombreUsuario,
        Validators.required,
      ], // this.registerForm.value.mail.split('@', 2)[0]
      idFinanciador: [65, Validators.required], // SIN FINANCIADOR
      nroCarnet: [""],
      idTipoValidacion: [1, Validators.required], // COBERTURA
    });
  }

  buildFormValidar_logueados() {
    let pattern_numeros = "[0-9]+";

    this.validarForm = this.formBuilder.group({
      fotoFrenteDNI: ["", Validators.required],
      fotoPaciente: ["", Validators.required],
      dni: [
        "",
        [
          Validators.required,
          Validators.pattern(pattern_numeros),
          Validators.minLength(6),
        ],
      ],
      tipoDni: ["", Validators.required], // se recupera con el user Data
      dniTemporal: [""], // se recupera con el user Data
      nombreUsuario: ["", Validators.required], // se recupera con el user Data
      idFinanciador: [65, Validators.required],
      nroCarnet: [""],
      idTipoValidacion: [1, Validators.required],
    });

    this.userService
      .getUserData()
      .toPromise()
      .then(
        (response) => {
          this.validarForm.get("dniTemporal").setValue(response.nroDocumento);
          this.validarForm.get("nombreUsuario").setValue(response.usuario);
          this.validarForm.get("tipoDni").setValue(response.tipoDocumento);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  get fvalidar() {
    return this.validarForm.controls;
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    let b: any = theBlob;

    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  };

  async onFileChange(event) {
    const reader = new FileReader();
    this.loadingCargandoHeic = true;
    ////////////
    // CONVERT HEIC TO JPG
    let file: File = event.target.files[0];
    let blob: Blob = event.target.files[0];
    let convProm: any;

    if (
      event.target.files[0].name.includes(".HEIC") ||
      event.target.files[0].name.includes(".heic")
    ) {
      convProm = await heic2any({ blob, toType: "image/jpeg", quality: 0.2 })
        .then((jpgBlob: Blob) => {
          //console.log(jpgBlob);

          //Change the name of the file according to the new format
          let newName = file.name.replace(/\.[^/.]+$/, ".jpeg");

          //Convert blob back to file
          file = this.blobToFile(jpgBlob, newName);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //This is not a HEIC image so we can just resolve
      convProm = Promise.resolve(true);
    }
    ////////////

    if (
      event.target.files &&
      event.target.files.length > 0 &&
      new String(file.type).match(/image.*/)
    ) {
      this.validarForm
        .get(event.target.getAttribute("id"))
        .setErrors({ archivoInvalido: false });
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.validarForm.get(event.target.getAttribute("id")).setValue({
          // nombre: new String(file.name).replace(/([^0-9a-zA-Z]+)/, ''),
          nombre: new String(file.name).replace(/[^a-zA-Z0-9.]+/g, ""),
          tipo: "." + this.splitString.transform(file.name),
          valor: (reader.result as string).split(",")[1],
        });
      };
    } else {
      this.validarForm
        .get(event.target.getAttribute("id"))
        .setErrors({ archivoInvalido: true });
      // this.validarForm.get(event.target.getAttribute('id')).setValue(null);
      // event.target.value = '';
    }
    this.loadingCargandoHeic = false;
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmitValidar() {
    this.submittedValidar = true;
    if (this.validarForm.invalid) {
      return;
    }

    this.loadingValidar = true;

    // Llamar al método "generarSolicitud" del servicio "userService"
    this.userService
      .generarSolicitud(this.validarForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          // Mostrar el mensaje de éxito al usuario
          this.mostrarMensajeExito();

        },
        (error) => { 
          // Mostrar el mensaje de error al usuario
          this.mostrarMensajeError();
        },
        () => {
          this.submittedValidar = false;
          this.loadingValidar = false;
        }
      );
  }

  // Método para mostrar el mensaje de éxito al usuario
  mostrarMensajeExito() {
    document.getElementById("openModalValidacionCorrecta").click();
  }

  // Método para mostrar el mensaje de error al usuario
  mostrarMensajeError() {
    document.getElementById("openModalValidacionIncorrecta").click();
  }

  async onSubmit() {
    this.submitted = true;
    this.loading = true;

    // stop here if registerForm is invalid
    if (this.registerForm.valid) {
      this.buildFormValidar();

      await this.userService.recuperarUsuarios(this.registerForm.value) // recupero los usuarios con MISMO DOCUMENTO
        .subscribe(
          x => {
            if(x.length == 0){ // NO existen USUARIOS con ese numero y tipo de doc, se hace el registro
              this.registrarUsuario();
              // console.log('se haría el registro');              
            }else{
              this.dialog.open(DialogMensajeConfirmarRegistroComponent,{
                data: {
                  nombreUsuario: x[0].usuario,
                  email: x[0].email
                }
              }).afterClosed().subscribe(
                result => {
                  switch (result) {
                    case 1:  
                      // console.log('Se recuperaría el usuario con email: '+ x[0].email);     
                      this.dialog.open(DialogOlvideMisDatosComponent, { 
                        data: { email: x[0].email },
                        hasBackdrop: false,
                      })               
                      break;
                
                    case 2:
                      this.registrarUsuario();
                      // console.log('se haría el registro');              
                      break;
                  }
                  this.loading=false;
                }                   
              );             
            }
          },
          error => {
            console.log(error);          
          })     
      
    } else {
      this.dialog.open(DialogMensajeComponent, {
        data: {
          ok: false,
          mensaje:
            "No fue posible completar el registro, por favor revise los datos ingresados e intente nuevamente",
        },
        panelClass: 'dialog-sin-overflow'
      });
      this.loading = false;
    }
  }

  registrarUsuario(){
    this.email=  this.registerForm.value.mail.toLowerCase();
    this.userService
        .register(this.registerForm.value)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.ok) {
              this.validarForm
                .get("dniTemporal")
                .setValue(data.usuario.nroDocumento);
              this.validarForm
                .get("tipoDni")
                .setValue(data.usuario.tipoDocumento);
                this.dialog.open(DialogRegistroExitosoComponent, {
                  data: {
                    email: this.email
                  },
                  panelClass: 'dialog-sin-overflow',
                  height: 'auto',
                  width: '600px',
                });
              this.vengo_de_registrarme = true;
              this.loading = false;
            } else {
              this.dialog.open(DialogMensajeComponent, {
                data: {
                  ok: false,
                  mensaje: data.mensaje,
                },
                panelClass: 'dialog-sin-overflow'
              });
              this.loading = false;
            }
          },
          (error) => {
            console.log(error);
          }
        );
  }

  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: {
          titulo: "Confirmar",
          mensaje: "cancelar el proceso de registro",      
          estasPor: true    
        },
        panelClass: 'dialog-sin-overflow'
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.router.navigate(["/login"]);
          // this.dialog.closeAll();
        }
      });
  }

  irLogin() {
    window.location.href = "/login";
  }

  irHome() {
    window.location.href = "/home";
  }

  openValidSheet(): void {
    this.bottomSheet.open(ValidRegister);
  }

  openInvalidSheet(): void {
    this.bottomSheet.open(InvalidRegister);
  }

  goHome() {
    this.router.navigate(["/login"]);
  }

  completarNomUsuario(event) {
    let inputValue = this.registerForm.value.mail.toLowerCase();
    this.registerForm.controls.mail.setValue(inputValue);
    this.registerForm.controls.nombreUsuario.setValue(inputValue.split("@", 2)[0]);
  }

  parseMinusculas(event) {
    this.registerForm.controls.nombreUsuario.setValue(this.registerForm.value.nombreUsuario.toLowerCase());
  }

  initForm() {
    // const emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|com\.ar|com\.br|org\.ar|gov|gov\.ar|cl|gob\.ar|es|co\.uk|edu\.ar|org|net\.ar|net|it|uba\.ar|col|com\.mx|)$/;
    const emailPattern: RegExp = 
    /^[a-zA-Z0-9-_.]([\.]?[a-zA-Z0-9-_.])+@[a-zA-Z0-9]([^@&%$\/\(\)=?¿!\.,:;\s]|\d)+[a-zA-Z0-9]([\.][a-zA-Z]{2,})+$/


    this.registerForm = this.formBuilder.group({
      firstName: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z \u00f1\u00d1]*")],
      ],
      lastName: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z \u00f1\u00d1]*")],
      ],
      mail: [
        "",
        [
          Validators.required,
          Validators.pattern(emailPattern),
          Validators.email
        ],
      ],
      nombreUsuario: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9_.-]*")],
      ],
      tel: [
        "",
        [
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(7),
          Validators.pattern("[0-9 ]*"),
        ],
      ],
      celular: ["", [Validators.required]],
      dni: [
        "",
        [
          Validators.required,
          Validators.maxLength(8),
          Validators.minLength(6),
          Validators.pattern("[0-9]*"),
        ],
      ],
      tipoDocumento: ["", Validators.required],
      date: ["", Validators.required],
      gender: ["", Validators.required],
      location: [
        "",
        [Validators.pattern("[a-zA-Z0-9 \u00f1\u00d1]*"), Validators.required],
      ],
      city: [
        "",
        [Validators.pattern("[a-zA-Z0-9 \u00f1\u00d1]*"), Validators.required],
      ],
      cod: ["", [Validators.pattern("[0-9]*"), Validators.required]],
    });
  }

  numeroFinal(numero: string) {
    this.registerForm.get("celular")?.setValue(numero);
  }

  getErrorMessageFirstName() {
    return this.registerForm.controls.firstName.hasError("required")
      ? "Este campo es obligatorio"
      : "Este campo solo permite letras";
  }

  getErrorMessageLastName() {
    return this.registerForm.controls.lastName.hasError("required")
      ? "Este campo es obligatorio"
      : "Este campo solo permite letras";
  }

  getErrorMessageNombreUsuario() {
    return this.registerForm.controls.nombreUsuario.hasError("required")
      ? "Este campo es obligatorio"
      : "Este campo solo permite letras, numeros y los caracteres: - _ .";
  }

  // getErrorMessageEmail() {   

  //   if(this.f.mail.hasError('required')){
  //     return "Este campo es obligatorio"
  //   }

  //   if(this.f.mail.hasError('email')){
  //     return "Ingrese un mail válido"
  //   }
  // }

  getErrorMessageDNI() {
    return this.registerForm.controls.dni.hasError("pattern")
      ? "Ingrese solo números"
      : this.registerForm.controls.dni.hasError("minlength")
      ? "Mínimo 6 dígitos"
      : this.registerForm.controls.dni.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }

  getErrorMessageTel() {
    return this.registerForm.controls.tel.hasError("pattern")
      ? "No ingresar caracteres especiales, letras ni espacios"
      : this.registerForm.controls.tel.hasError("minlength")
      ? "Ingrese un numero de al menos 7 dígitos"
      : this.registerForm.controls.tel.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }

  getErrorMessageFechaNac() {
    //return this.registerForm.controls.date.hasError('required') ? 'Este campo es obligatorio': '';

    let regexp = new RegExp(
      /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/gm
    );
    let formato_correcto: boolean;

    if (typeof this.registerForm.controls.fechaNacimiento != "undefined") {
      var fecha = new Date(this.registerForm.controls.fechaNacimiento.value);
      var mes = fecha.getMonth() + 1;
      this.fechaNacimientoString =
        fecha.getDate() + "/" + mes + "/" + fecha.getFullYear();

      formato_correcto = regexp.test(this.fechaNacimientoString);
    } else {
      formato_correcto = true;
    }

    return formato_correcto
      ? "Ingrese dia/mes/año o utilice el calendario"
      : this.registerForm.controls.fechaNacimiento.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }

  getErrorMessageGender() {
    return this.registerForm.controls.gender.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }

  getErrorMessageDomicilio() {
    return this.registerForm.controls.location.hasError("pattern")
      ? "Ingrese solo letras y números"
      : this.registerForm.controls.location.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }

  getErrorMessageLocalidad() {
    return this.registerForm.controls.city.hasError("pattern")
      ? "Ingrese solo letras y números"
      : this.registerForm.controls.city.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }

  getErrorMessageCP() {
    return this.registerForm.controls.cod.hasError("pattern")
      ? "Ingrese solo números"
      : this.registerForm.controls.cod.hasError("required")
      ? "Este campo es obligatorio"
      : "";
  }
}

@Component({
  selector: "valid-register",
  templateUrl: "valid-register.html",
})
export class ValidRegister {
  constructor(private bottomSheetRef: MatBottomSheetRef<ValidRegister>) {}

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}

@Component({
  selector: "invalid-register",
  templateUrl: "invalid-register.html",
})
export class InvalidRegister {
  constructor(private bottomSheetRef: MatBottomSheetRef<InvalidRegister>) {}

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
