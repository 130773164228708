import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  ok: boolean;
  titulo: string;
  mensaje: string;
  botones: BotonAccion[];
}

export interface BotonAccion {
  texto: string;
  ruta?: string;  
  cerrarDialogo?: boolean; 
  color?: string;
}

@Component({
  selector: 'app-dialog-mensaje-botones-general',
  templateUrl: './dialog-mensaje-botones-general.component.html',
  styleUrls: ['./dialog-mensaje-botones-general.component.css']
})
export class DialogMensajeBotonesGeneralComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogMensajeBotonesGeneralComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router
  ) {}

  ngOnInit(): void {
  }

  cerrarDialogo(accion: string): void {
    this.dialogRef.close(accion);
  }

  ejecutarAccion(boton: BotonAccion): void {
    if (boton.ruta) {
      this.router.navigate([boton.ruta]); 
    }

    if (boton.cerrarDialogo !== false) {
      // Si no se quiere cerrar el dialogo despues de la accion, en la invocacion se debe enviar 'cerrarDialogo: true'
      this.dialogRef.close();
    }
  }

}