import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSortModule } from '@angular/material/sort';
import { safeUrlPipe } from './_pipes/safeUrl.pipe';
import { SplitStringPipe } from './_pipes/split-string.pipe';
import { toTitleCasePipe } from './_pipes/toTitleCase.pipe';
import { censoredDniPipe } from './_pipes/censoredDni.pipe';
import { censoredBirthDatePipe } from './_pipes/censoredBirthDate.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ObrasSocialesPrepagasComponent } from './obras-sociales-prepagas/obras-sociales-prepagas.component';
import { HospitalDelDiaComponent } from './hospital-del-dia/hospital-del-dia.component';
import { NefrologiaYDialisisComponent } from './nefrologia-y-dialisis/nefrologia-y-dialisis.component';
import { TerapiaIntensivaComponent } from './terapia-intensiva/terapia-intensiva.component';
import { RecomendacionesComponent } from './recomendaciones/recomendaciones.component';
import { DerechosDelPacienteComponent } from './derechos-del-paciente/derechos-del-paciente.component';
import { NormasDeConvivenciaComponent } from './normas-de-convivencia/normas-de-convivencia.component';
import { VisitasComponent } from './visitas/visitas.component';
import { EgresosComponent } from './egresos/egresos.component';
import { TramitesAdministrativosComponent } from './tramites-administrativos/tramites-administrativos.component';
import { EstudiosPrequirurgicosComponent } from './estudios-prequirurgicos/estudios-prequirurgicos.component';
import { PreparacionComponent } from './preparacion/preparacion.component';
import { AdmisionComponent } from './admision/admision.component';
import { CardiologiaComponent } from './cardiologia/cardiologia.component';
import { EcocardiogramasComponent } from './ecocardiogramas/ecocardiogramas.component';
import { EcografiaGeneralComponent } from './ecografia-general/ecografia-general.component';
import { EcografiaPediatricaComponent } from './ecografia-pediatrica/ecografia-pediatrica.component';
import { DiagnosticoPrenatalYMujerComponent } from './diagnostico-prenatal-y-mujer/diagnostico-prenatal-y-mujer.component';
import { HematologiaComponent } from './hematologia/hematologia.component';
import { HemodinamiaComponent } from './hemodinamia/hemodinamia.component';
import { LaboratorioComponent } from './laboratorio/laboratorio.component';
import { MamografiasComponent } from './mamografias/mamografias.component';
import { MedicinaNuclearComponent } from './medicina-nuclear/medicina-nuclear.component';
import { RadiologiaComponent } from './radiologia/radiologia.component';
import { ResonanciaMagneticaComponent } from './resonancia-magnetica/resonancia-magnetica.component';
import { FacturasDeGastosComponent } from './facturas-de-gastos/facturas-de-gastos.component';
import { FacturasDeHonorariosComponent } from './facturas-de-honorarios/facturas-de-honorarios.component';
import {
  RegisterComponent,
  ValidRegister,
  InvalidRegister,
} from './register/register.component';
import { HistorialClinicoComponent } from './historial-clinico/historial-clinico.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TurnosComponent } from './turnos/turnos.component';
import { TurnosPacienteHistoricoComponent } from './turnos-paciente-historico/turnos-paciente-historico.component';
import { MisReservasComponent } from './mis-reservas/mis-reservas.component';
import { MatButtonModule } from '@angular/material/button';
import { WebCheckinComponent } from './web-checkin/web-checkin.component';
import { MatRadioModule } from '@angular/material/radio';
import { NoticiasComponent } from './noticias/noticias.component';
import { AutorizarCoberturaComponent } from './autorizar-cobertura/autorizar-cobertura.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PagoComponent } from './pago/pago.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { VisualizarEstudioComponent } from './visualizar-estudio/visualizar-estudio.component';
import { VisualizarHistorialComponent } from './visualizar-historial/visualizar-historial.component';
import { ProfesionalesComponent } from './profesionales/profesionales.component';
import { ValidarUsuarioComponent } from './validar-usuario/validar-usuario.component';
import { TurnosDxiComponent } from './turnos-dxi/turnos-dxi.component';
import { OrdenesComponent } from './ordenes/ordenes.component';
import { ActualizarDatosComponent } from './actualizar-datos/actualizar-datos.component';
import { DialogMensajeComponent } from './dialog-mensaje/dialog-mensaje.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogConfirmacionComponent } from './dialog-confirmacion/dialog-confirmacion.component';
import { TomografiaComputarizadaComponent } from './tomografia-computarizada/tomografia-computarizada.component';
import { EstudiosExternosComponent } from './estudios-externos/estudios-externos.component';
import { EpisodioComponent } from './episodio/episodio.component';
import { DialogCompartirComponent } from './dialog-compartir/dialog-compartir.component';
import { CancelarTurnoComponent } from './cancelar-turno/cancelar-turno.component';
import { AlertComponent } from './_components/alert.component';
import { MisCoberturasComponent } from './mis-coberturas/mis-coberturas.component';
import { DialogAgregarCoberturaComponent } from './dialog-agregar-cobertura/dialog-agregar-cobertura.component';
import { DialogActualizarContrasenaComponent } from './dialog-actualizar-contrasena/dialog-actualizar-contrasena.component';
import { DialogTerminosYCondicionesComponent } from './dialog-terminos-y-condiciones/dialog-terminos-y-condiciones.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HomeComponent } from './home/home.component';
import { DialogConfirmarTurnoComponent } from './dialog-confirmar-turno/dialog-confirmar-turno.component';
import { DialogMensajeTurnoComponent } from './dialog-mensaje-turno/dialog-mensaje-turno.component';
import { ReservarTurnoComponent } from './reservar-turno/reservar-turno.component';
import { MatGridList, MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { FacturasComponent } from './facturas/facturas.component';
import { PlatformModule } from '@angular/cdk/platform';
import { MenuSandwichComponent } from './menu-sandwich/menu-sandwich.component';
import { ReservarTurnoMedicoComponent } from './reservar-turno-medico/reservar-turno-medico.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DialogSoporteTecnicoComponent } from './dialog-soporte-tecnico/dialog-soporte-tecnico.component';
import { DialogOlvideMisDatosComponent } from './dialog-olvide-mis-datos/dialog-olvide-mis-datos.component';
import { DialogAutorizarCoberturaComponent } from './dialog-autorizar-cobertura/dialog-autorizar-cobertura.component';
import { DialogAutorizarCoberturaResultadoComponent } from './dialog-autorizar-cobertura-resultado/dialog-autorizar-cobertura-resultado.component';
import { DialogLlegadaPacienteComponent } from './dialog-llegada-paciente/dialog-llegada-paciente.component';
import { DialogReprogramarTurnoComponent } from './dialog-reprogramar-turno/dialog-reprogramar-turno.component';
import { InternacionComponent } from './internacion/internacion.component';
import { PacientesQuirurgicosComponent } from './pacientes-quirurgicos/pacientes-quirurgicos.component';
import { AreasInternacionPrestacionesEspecialesComponent } from './areas-internacion-prestaciones-especiales/areas-internacion-prestaciones-especiales.component';
import { IngresoEgresoComponent } from './ingreso-egreso/ingreso-egreso.component';
import { DialogCampaniaDeActualizacionComponent } from './dialog-campania-de-actualizacion/dialog-campania-de-actualizacion.component';
import { DialogMensajeConfirmarRegistroComponent } from './dialog-mensaje-confirmar-registro/dialog-mensaje-confirmar-registro.component';
import { DialogMensajeAyudaComponent } from './dialog-mensaje-ayuda/dialog-mensaje-ayuda.component';
import { DialogValoracionUsuarioComponent } from './dialog-valoracion-usuario/dialog-valoracion-usuario.component';
import { AngularEmojisModule } from 'angular-emojis';
import { CarouselComponent } from './carousel/carousel.component';
import { DialogRegistroExitosoComponent } from './dialog-registro-exitoso/dialog-registro-exitoso.component';
import { DialogRecuperarDatosComponent } from './dialog-recuperar-datos/dialog-recuperar-datos.component';
import { DialogExpandirImagenesComponent } from './dialog-expandir-imagenes/dialog-expandir-imagenes.component';
import { PhoneFormComponent } from './phone-form/phone-form.component';
import { DialogMensajeBotonesGeneralComponent } from './dialog-mensaje-botones-general/dialog-mensaje-botones-general.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,    
    ObrasSocialesPrepagasComponent,
    HospitalDelDiaComponent,
    NefrologiaYDialisisComponent,
    TerapiaIntensivaComponent,
    RecomendacionesComponent,
    DerechosDelPacienteComponent,
    NormasDeConvivenciaComponent,
    VisitasComponent,
    EgresosComponent,
    TramitesAdministrativosComponent,
    EstudiosPrequirurgicosComponent,
    PreparacionComponent,
    AdmisionComponent,
    CardiologiaComponent,
    EcocardiogramasComponent,
    EcografiaGeneralComponent,
    EcografiaPediatricaComponent,
    DiagnosticoPrenatalYMujerComponent,
    HematologiaComponent,
    HemodinamiaComponent,
    LaboratorioComponent,
    MamografiasComponent,
    MedicinaNuclearComponent,
    RadiologiaComponent,
    ResonanciaMagneticaComponent,
    FacturasDeGastosComponent,
    FacturasDeHonorariosComponent,
    RegisterComponent,
    ValidRegister,
    InvalidRegister,
    HistorialClinicoComponent,
    safeUrlPipe,
    SplitStringPipe,
    toTitleCasePipe,
    censoredDniPipe,
    censoredBirthDatePipe,
    TurnosComponent,
    TurnosPacienteHistoricoComponent,
    MisReservasComponent,
    WebCheckinComponent,
    NoticiasComponent,
    AutorizarCoberturaComponent,
    PagoComponent,
    VisualizarEstudioComponent,
    VisualizarHistorialComponent,
    ProfesionalesComponent,
    ValidarUsuarioComponent,
    TurnosDxiComponent,
    OrdenesComponent,
    ActualizarDatosComponent,
    DialogMensajeComponent,
    DialogConfirmacionComponent,
    TomografiaComputarizadaComponent,
    EstudiosExternosComponent,
    EpisodioComponent,
    DialogCompartirComponent,
    CancelarTurnoComponent,
    MisCoberturasComponent,
    DialogAgregarCoberturaComponent,
    DialogActualizarContrasenaComponent,
    DialogTerminosYCondicionesComponent,
    HomeComponent,
    DialogConfirmarTurnoComponent,
    DialogMensajeTurnoComponent,
    ReservarTurnoComponent,
    MenuLateralComponent,
    FacturasComponent,
    MenuSandwichComponent,
    ReservarTurnoMedicoComponent,
    DialogSoporteTecnicoComponent,
    DialogOlvideMisDatosComponent,
    DialogAutorizarCoberturaComponent,
    DialogAutorizarCoberturaResultadoComponent,
    DialogLlegadaPacienteComponent,
    DialogReprogramarTurnoComponent,
    InternacionComponent,
    PacientesQuirurgicosComponent,
    AreasInternacionPrestacionesEspecialesComponent,
    IngresoEgresoComponent,
    DialogCampaniaDeActualizacionComponent,
    DialogMensajeConfirmarRegistroComponent,
    DialogMensajeAyudaComponent,
    DialogValoracionUsuarioComponent,
    CarouselComponent,
    DialogRegistroExitosoComponent,
    DialogRecuperarDatosComponent,
    DialogExpandirImagenesComponent,
    PhoneFormComponent,
    DialogMensajeBotonesGeneralComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatInputModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatBottomSheetModule,
    MatSortModule,
    MatTableModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatNativeDateModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatCardModule,
    MatDialogModule,
    PdfViewerModule, 
    CommonModule,
    MatGridListModule,
    MatDividerModule,
    PlatformModule,
    PdfViewerModule,
    MatAutocompleteModule,
    MatChipsModule,
    InfiniteScrollModule,
    MatCheckboxModule,
    AngularEmojisModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
