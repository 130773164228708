import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';

@Injectable({ providedIn: 'root' })
export class TwilioService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = AppComponent.testUrl
  }

  verificarNumeroCelular(numCel: string): Observable<any> {
    let params = new HttpParams()
    .set('num', encodeURIComponent(numCel));
    return this.http.get<any>(this.baseUrl + '/verificar-numero-celular', {params});
  } 
}