<!-- <mat-toolbar style="
    background: #0a3d6e !important;
    color: #fff !important;
    opacity: 0.9 !important;
    height: 64px;
  ">
    <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <h1 style="font-family: Helvetica" style="cursor: pointer">
                <a (click)="goHome()">
                    <img id="logo" src="../../assets/images/login/logo HE.png" /> pacientes
                </a>
            </h1>
        </div>
    </div>
</mat-toolbar> -->
<div class="container">
    <div *ngIf="!voyAValidar; else elseBlock">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-row mt-3">
                <!-- Input campo nombre -->
                <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Nombres</mat-label>
                        <input matInput formControlName="firstName" autocomplete="off" />
                        <mat-error *ngIf="f.firstName.invalid">{{ getErrorMessageFirstName() }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Input campo apellido -->
                <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Apellidos</mat-label>
                        <input matInput formControlName="lastName" autocomplete="off" />
                        <mat-error *ngIf="f.lastName.invalid">{{ getErrorMessageLastName() }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-row">
                <!-- Input campo email -->
                <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input matInput
                      placeholder="ejemplo@gmail.com" 
                      formControlName="mail" 
                      autocomplete="on" 
                      id="inputEmail" 
                      (keyup)="completarNomUsuario($event)" />
                        <mat-error *ngIf="f.mail.hasError('required')">Este campo es obligatorio</mat-error>
                        <mat-error *ngIf="f.mail.hasError('pattern')">Ingrese un email válido</mat-error>
                    </mat-form-field>
                </div>

                <!-- Nuevo campo nombre de usuario -->
                <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-form-field id="campo_nombre_usuario" class="col-12" hintLabel="Se permiten letras min&uacute;sculas, números y los siguientes caracteres: - _ ." appearance="outline">
                        <mat-label>Nombre de usuario</mat-label>
                        <input matInput formControlName="nombreUsuario" id="inputUsuario" autocomplete="off" (keyup)="parseMinusculas($event)"/>
                        <mat-error *ngIf="f.nombreUsuario.invalid">{{ getErrorMessageNombreUsuario() }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Input campo telefono -->
                <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <mat-form-field class="col-12" hintLabel="Min. 7 dígitos" appearance="outline">
                        <mat-label>Telefono</mat-label>
                        <input matInput #inputTelefono formControlName="tel" autocomplete="off" maxlength="20" />
                        <mat-error *ngIf="f.tel.invalid">{{ getErrorMessageTel() }}
                        </mat-error>
                        <mat-hint align="end">{{ inputTelefono.value?.length || 0 }}/20</mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <!-- Fromulario de Celular -->
            <p class="col-12">Celular: <b style="color: red;">Recuerde verificarlo para continuar</b></p>
            <app-phone-form (numeroFinal)="numeroFinal($event)"></app-phone-form>

            <div class="form-row">
                <!-- Input campo Número de Documento -->
                <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <mat-form-field class="col-12" hintLabel="Min. 6 dígitos" appearance="outline">
                        <mat-label>Número de documento</mat-label>
                        <input matInput #inputDNI autocomplete="off" formControlName="dni" maxlength="8" />
                        <mat-error *ngIf="f.dni.invalid">{{ getErrorMessageDNI() }}
                        </mat-error>
                        <mat-hint align="end">{{ inputDNI.value?.length || 0 }}/8</mat-hint>
                    </mat-form-field>
                </div>

                <!-- Input campo Tipo de Documento -->
                <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Tipo de documento</mat-label>
                    <mat-select name="tipoDocumento" formControlName="tipoDocumento" required>
                      <mat-option *ngFor="let td of tiposDocumentos" value="{{td.codTipo}}">
                        {{td.desc}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>

                <!-- Input campo Fecha de nacimiento -->
                <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label>Fecha de nacimiento</mat-label>
                        <input autocomplete="off" formControlName="date" matInput [max]="fecha_max" [matDatepicker]="nuevaFecha" />
                        <mat-error *ngIf="f.date.invalid">{{ getErrorMessageFechaNac() }}
                        </mat-error>
                        <mat-datepicker-toggle matSuffix [for]="nuevaFecha"></mat-datepicker-toggle>
                        <mat-datepicker #nuevaFecha></mat-datepicker>
                    </mat-form-field>
                </div>

                <!-- Input campo genero -->
                <div class="form-group col-xs-8 col-sm-8 col-md-8 col-lg-4 col-xl-4">
                    <label class="row ml-4">Sexo</label>
                    <div class="row ml-4 mt-2">
                        <mat-radio-group formControlName="gender">
                            <mat-radio-button value="M">Masculino</mat-radio-button>
                            <mat-radio-button value="F">Femenino</mat-radio-button>
                            <mat-error *ngIf="f.gender.invalid && submitted">{{ getErrorMessageGender() }}
                            </mat-error>
                        </mat-radio-group>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <!-- Input campo domicilio -->
                <div class="form-group col-xs-6 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Domicilio</mat-label>
                        <input matInput formControlName="location" #inputDomicilio autocomplete="off" maxlength="40" />
                        <mat-error *ngIf="f.location.invalid">{{ getErrorMessageDomicilio() }}
                        </mat-error>
                        <mat-hint align="end">{{ inputDomicilio.value?.length || 0 }}/40</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Localidad</mat-label>
                        <input matInput formControlName="city" #inputCity autocomplete="off" maxlength="25" />
                        <mat-error *ngIf="f.city.invalid">{{ getErrorMessageLocalidad() }}
                        </mat-error>
                        <mat-hint align="end">{{ inputCity.value?.length || 0 }}/25</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2">
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Código postal</mat-label>
                        <input matInput autocomplete="off" #inputCP maxlength="4" formControlName="cod" />
                        <mat-error *ngIf="f.cod.invalid">{{ getErrorMessageCP() }}
                        </mat-error>
                        <mat-hint align="end">{{ inputCP.value?.length || 0 }}/4</mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group float-right d-flex align-items-center">
                <button [disabled]="loading" type="submit" class="boton boton-confirmar mx-2 my-3">
                  Registrarse
                </button>
                <mat-spinner *ngIf="loading" [diameter]="30" class="float-right align-middle ml-1"></mat-spinner>
            </div>
        </form>
        
        <button [disabled]="loading" (click)="cancelar()" class="boton boton-cerrar float-right mx-2 my-3">
          Cancelar
        </button>
    </div>
    <ng-template #elseBlock>
        <div class="form-row pt-2">
            <div class="container">
                <p style="margin-bottom: 5vh;">
                    Para completar el proceso adjuntá foto del frente de tu DNI y de tu rostro (sin accesorios).
                </p>
                <form [formGroup]="validarForm" (ngSubmit)="onSubmitValidar()">

                    <!-- Input campo DNI -->
                    <div *ngIf="!vengo_de_registrarme" class="form-group">
                      <label for="dni">DNI</label>
                      <input
                        #inputDNI
                        autocomplete="off"
                        type="text"
                        formControlName="dni"
                        maxlength="8"
                        class="form-control"
                        id="inputDNI"
                        [ngClass]="{
                          'is-invalid': submittedValidar && fvalidar.dni.errors
                        }"
                      />
                      <div style="font-size: 12px;
                        margin-left: 2vw;">Dígitos ingresados: {{inputDNI.value?.length || 0}}/8</div>
                      <div                                              
                        *ngIf="fvalidar.dni.errors"
                        class="invalid-feedback"
                      >
                        
                        <div *ngIf="fvalidar.dni.errors.required">
                          El número de tu DNI es requerido
                        </div>
                        <div *ngIf="fvalidar.dni.errors.minlength">
                          Debe tener al menos 6 digitos
                        </div>
                        <div *ngIf="fvalidar.dni.errors.pattern">
                          Debe contener solamente números
                        </div>
                      </div>                      
                    </div>                   

                    <div class="row py-2 d-flex align-items-center">
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                            <div class="form-group">
                                <label for="dni">Foto del frente de tu DNI</label>
                                <mat-spinner *ngIf = "this.loadingCargandoHeic" [diameter]="30" class="float-right align-middle ml-1"></mat-spinner>   
                                <input [disabled]="loadingValidar || loadingCargandoHeic" style="transform: scale(.9);" type="file" (change)="onFileChange($event)" accept="image/*, .heic" id="fotoFrenteDNI" class="form-control" required [ngClass]="{
                    'is-invalid':
                      (submittedValidar && fvalidar.fotoFrenteDNI.errors) ||
                      (fvalidar.fotoFrenteDNI.errors &&
                        fvalidar.fotoFrenteDNI.errors.archivoInvalido)
                  }" />
                                <div *ngIf="submittedValidar && fvalidar.fotoFrenteDNI.errors" class="invalid-feedback">
                                    <div *ngIf="fvalidar.fotoFrenteDNI.errors.required">
                                        La foto de tu DNI es requerida
                                    </div>
                                </div>
                                <div *ngIf="fvalidar.fotoFrenteDNI.errors" class="invalid-feedback">
                                    <div *ngIf="fvalidar.fotoFrenteDNI.errors.archivoInvalido">
                                        Solo se admiten imágenes
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center">
                            <img id='img-frente-dni' src="./../../assets/images/validar-usuario/frenteDNI.png" alt="Foto frente DNI" class="img-fluid p-1" />
                        </div>
                    </div>
                    <div class="row py-2 d-flex align-items-center">
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                            <div class="form-group">
                                <label for="dni">Foto de tu rostro sin accesorios</label>
                                <mat-spinner *ngIf = "this.loadingCargandoHeic" [diameter]="30" class="float-right align-middle ml-1"></mat-spinner>   
                <input
                  [disabled]="loadingValidar || loadingCargandoHeic"
                  style="transform: scale(.9);"
                  type="file"
                  (change)="onFileChange($event)"
                  accept="image/*, .heic"
                  id="fotoPaciente"
                  class="form-control"
                  required
                  [ngClass]="{
                    'is-invalid':
                      (submittedValidar && fvalidar.fotoPaciente.errors) ||
                      (fvalidar.fotoPaciente.errors &&
                        fvalidar.fotoPaciente.errors.archivoInvalido)
                  }"
                />
                <div
                  *ngIf="submittedValidar && fvalidar.fotoPaciente.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="fvalidar.fotoPaciente.errors.required">
                    La foto de tu rostro es requerida
                  </div>
                </div>
                <div
                  *ngIf="fvalidar.fotoPaciente.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="fvalidar.fotoPaciente.errors.archivoInvalido">
                    Solo se admiten imagenes
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center"
            >
              <img
                id="img-selfie"
                src="./../../assets/images/validar-usuario/selfie.png"
                alt="Foto frente DNI"
                class="img-fluid p-1"
              />
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <!-- <button routerLink="/login" class="btn btn-secondary mr-2">
              Cancelar
            </button> -->
            <img *ngIf="loadingValidar" class="pl-3" />
            <button [disabled]="loadingValidar || loadingCargandoHeic" class="btn btn-naranjita ml-2">
              <mat-spinner *ngIf="this.loadingValidar" [diameter]="30" class="float-right align-middle ml-1"></mat-spinner>
              Validar
            </button>
            
          </div>
          <div
            class="text-danger"
            *ngIf="submittedValidar && this.validarForm.invalid"
          >
            <h6>
              <strong>ATENCION:</strong> No fue posible completar la validación,
              por favor revise que los datos ingresados esten completos e
              intente nuevamente
            </h6>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <div>
    <!-- Modal Registro Valido -->
    <!--
    <button
      id="openModalRegistroValido"
      type="button"
      class="btn btn-info btn-lg"
      data-toggle="modal"
      data-target="#modalRegistroValido"
      [hidden]="true"
    >
      Open The Modal Box
    </button>
    <button
      id="closeModalRegistroValido"
      type="button"
      class="btn btn-info btn-lg"
      data-dismiss="modal"
      [hidden]="true"
    >
      Open The Modal Box
    </button>
    <div
      class="modal fade"
      id="modalRegistroValido"
      data-backdrop="false"
      data-dismiss="modal"
      data-keyboard="false"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body"> -->
            <!-- <h5 style="text-align: center; color: rgb(13, 52, 83)">
              <strong>&iexcl;Felicitaciones!</strong>
            </h5>
            <p>
              Usted ha completado exitosamente el proceso de registro. Por
              favor, revise su cuenta de correo para ver sus datos de ingreso.
            </p>
            <p>
              Ya puede ver el listado completo de profesionales y gestionar
              turnos desde su celular o PC.
            </p>
            <p>
              Para poder acceder a su Historial cl&iacute;nico, estudios de
              Laboratorio, Hematolog&iacute;a y Diagn&oacute;stico por
              Im&aacute;genes; facturas y comprobantes; y hacer su
              admisi&oacute;n digital deberá validar su identidad previamente
            </p>
            <h5 style="text-align: center; color: rgb(13, 52, 83)">
              <strong>¿Desea validar su usuario ahora?</strong>
            </h5> -->
            <!-- <h5 style="text-align: center; color: rgb(13, 52, 83)">
              <strong>¡Bienvenido/a!</strong>
            </h5>
            <p>
              Se ha realizado de forma exitosa el proceso de registro de su
              usuario.
            </p>

            <ul>
              <li>
                Sus datos de ingreso (usuario | clave) se han enviado a su
                correo.
              </li>
              <li>
                Ahora podrá acceder al listado completo de profesionales y sacar
                turno las 24hs
              </li>
            </ul>
            <p>
              *para acceder a su Historia Clínica deberá VALIDAR su identidad.
            </p> -->
            <!--
            <h5 style="text-align: center; color: rgb(13, 52, 83)">
              <strong>¡Bienvenido/a!</strong>
            </h5>
            <p>
              Se ha realizado de forma exitosa el proceso de registro de tu
              usuario.
            </p>

            <ul>
              <li>
                Tus datos de ingreso (usuario | clave) se enviaron a tu correo.
              </li>
              <li>
                Ahora vas a poder acceder al listado completo de profesionales y sacar turno las 24hs.
              </li>
            </ul>
            <div style="font-style: italic;">
              <p>
                *para proteger tus datos personales validá tu identidad, haciendo esto vas a poder acceder a tu Historia Clínica.
              </p>
              <p>*te sugerimos tener el DNI cerca.</p>
            </div>
          </div>
          <div class="modal-footer">
            <button  
              type="button"
              class="btn btn-secondary ml-2"
              data-dismiss="modal"
              (click)="irLogin()"
            >
              Omitir
            </button>
            <button
              type="button"
              class="btn btn-naranjita-sin-hover ml-2"
              data-dismiss="modal"
              (click)="voyAValidar = !voyAValidar"
            >
              Validar ahora
            </button>
          </div>
        </div>-->
      </div>
    </div>

    <!-- Modal Registro Invalido -->
    <button
      id="openModalRegistroInvalido"
      type="button"
      class="btn btn-info btn-lg"
      data-toggle="modal"
      data-target="#modalRegistroInvalido"
      [hidden]="true"
    >
      Open The Modal Box
    </button>
    <button
      id="closeModalRegistroInvalido"
      type="button"
      class="btn btn-info btn-lg"
      data-dismiss="modal"
      [hidden]="true"
    >
      Open The Modal Box
    </button>
    <div
      class="modal fade"
      id="modalRegistroInvalido"
      data-backdrop="false"
      data-dismiss="modal"
      data-keyboard="false"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <!-- <div class="modal-header">
          <h5>Su turno ha sido reservado con éxito.</h5>
      </div> -->
          <div class="modal-body">
            <p>
              El nombre de usuario ingresado ya existe, por favor intente con
              otro.
            </p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Validacion Correcta -->
    <button
      id="openModalValidacionCorrecta"
      type="button"
      class="btn btn-info btn-lg"
      data-toggle="modal"
      data-target="#modalValidacionCorrecta"
      [hidden]="true"
    >
      Open The Modal Box
    </button>
    <button
      id="closeModalValidacionCorrecta"
      type="button"
      class="btn btn-info btn-lg"
      data-dismiss="modal"
      [hidden]="true"
    >
      Open The Modal Box
    </button>
    <div
      class="modal fade"
      id="modalValidacionCorrecta"
      data-backdrop="false"
      data-dismiss="modal"
      data-keyboard="false"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <!-- <div class="modal-header">
          <h5>Su turno ha sido reservado con éxito.</h5>
      </div> -->
          <!-- <div class="modal-body">
            <p>
              Su solicitud de validación se realizo correctamente. Recuerde que
              el proceso de validación puede demorar un tiempo.
            </p>
          </div> -->
          <div class="modal-header">
            <h5>¡Felicitaciones!</h5>
          </div>
          <div class="modal-body">
            <!-- <p>
          Su solicitud de validación se realizo correctamente. Recuerde que el
          proceso de validación puede demorar un tiempo.
        </p> -->
            <p>
              Se ha realizado de forma exitosa la solicitud de validación de
              identidad.
            </p>
            <p>El proceso se completará dentro de las 48hs.</p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-info"
              type="button"
              data-dismiss="modal"
              (click)="vengo_de_registrarme ? irLogin() : irHome()"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Validacion Incorrecta -->
    <button
      id="openModalValidacionIncorrecta"
      type="button"
      class="btn btn-info btn-lg"
      data-toggle="modal"
      data-target="#modalValidacionIncorrecta"
      [hidden]="true"
    >
      Open The Modal Box
    </button>
    <button
      id="closeModalValidacionIncorrecta"
      type="button"
      class="btn btn-info btn-lg"
      data-dismiss="modal"
      [hidden]="true"
    >
      Open The Modal Box
    </button>
    <div
      class="modal fade"
      id="modalValidacionIncorrecta"
      data-backdrop="false"
      data-dismiss="modal"
      data-keyboard="false"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <!-- <div class="modal-header">
          <h5>Su turno ha sido reservado con éxito.</h5>
      </div> -->
          <div class="modal-body">
            <p>Hubo un error al ingresar los datos de validación.</p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>